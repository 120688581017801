<template>
  <img v-if="circleId != null && Boolean(hasImg)" :src="imgUrl" :alt="circleName">
  <img v-else-if="circleId != null && !Boolean(hasImg)" :src="nonCircleIcon" :alt="circleName">
</template>

<script>


import Common from '../../../js/common.js'


export default {
  props : {
    hasImg : {
      type : Boolean,
      required: true
    },
    circleId : {
      type : Number,
      required : false
    },
    circleName : {
      type : String,
      required : false
    },
  },
  computed : {
    imgUrl () {   return '/app/images/circle/' + this.circleId },
    nonCircleIcon () { return Common.getBaseUrl() + this.Global.static_path + '/images/common/circle_icon.png' }
  },
}
</script>