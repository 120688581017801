<template>
  <ul :class="[ {sub : mode == mobile } ]">
    <li><router-link to = "/about-cotolike">このサイトについて</router-link></li>
    <li><router-link to = "/privacy-policy">プライバシーポリシー</router-link></li>
    <li><router-link to = "/terms-service">利用規約</router-link></li>
  </ul>
</template>
<script>
export default {
  props:{
    mode:{
      type:Number,
      required:true
    }
  },
  computed : {
    mobile : function () {
      return this.Global.device_division_id.mobile
    }
  }
}
</script>
