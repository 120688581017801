<template>
  <div id="user-dropdown">
    <button @click="toggleMenu">
      <div class="user">
        <c-account-image :has-img = "account.has_img" :account-id = "account.id" :account-name = "account.last_name + account.first_name" />
      </div>
    </button>
    <div class="menu" v-show="Boolean(menuDisplay)" @mouseleave="onMouseleave">
      <router-link to = "/admin/menu" v-if="loginFlg && account.admin_flg"><font-awesome-icon icon="tools" v-tooltip="'管理メニュー'" class="header-menu-icon" />管理メニュー</router-link>
      <!-- <router-link :to = "{ name:'myspace', params:{ accountId: account.id} }"><font-awesome-icon icon="home" v-tooltip="'マイスペース'" class="header-menu-icon" />マイスペース</router-link> -->
      <router-link to="/settings"><font-awesome-icon icon="user-cog" v-tooltip="'設定'" class="header-menu-icon" />設定</router-link>
      <c-logout-anchor :icon="true" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LogoutAnchor from '../logout-anchor'

export default {
  data () {
    return {
      menuDisplay : false
    }
  },
  computed: {
    ...mapState([
      'loginFlg',
      'account'
    ]),
  },
  methods : {
    toggleMenu : function () {
      this.menuDisplay = !this.menuDisplay
    },
    onMouseleave : function () {
      this.menuDisplay = false
    }
  },
  components : {
    'c-logout-anchor' : LogoutAnchor,
  }
}
</script>