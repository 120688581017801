<template>
  <div class="comment-action-overlay">
    <div class="action-overlay" v-show="commentEditContainerDisplay">
        <div class="container">
          <div class="item">
            <mentionable
              :keys="keys"
              :items="items"
              :limit="mentionLimit"
              insert-space>
              <textarea placeholder="@名前を入力してください" v-model="text" ref="area" :style="styles"></textarea>
              <template #no-result>
                <div class="dim">
                  No result
                </div>
              </template>
              <template #item-@="{ item }">
                <div class="destination" v-if="!item.groupFlg">
                  {{ item.lastName + item.firstName }}
                  <span class="dim">
                    {{ item.nickname }}
                  </span>
                </div>
                <div class="group" v-else>
                  {{ item.name }}
                  <span class="dim">
                    ({{ item.count }}名)
                  </span>
                </div>
              </template>
            </mentionable>
          </div>
          <div class="action">
            <button class="cancel" @click="cancel">キャンセル</button>
            <button class="confirm" @click="save">保存</button>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import mentionUtils from '../../js/common/entry/MentionUtils';
import _ from 'lodash'
import entries from '../../js/api/entries';

export default {
  data () {
    return {
      parentEntryId: null,
      entry: null,
      text: null,
      keys: ['@'],
      mentionLimit: this.Global.vue_mention_limit,
      items: [],
      accounts: null,
      groups: null,
      groupToAccounts: {},
      height: '70px'
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
      'commentEditContainerDisplay',
      'commentEditContainerContext',
      'allAccounts',
      'circles',
      'followers',
    ]),
    styles() {
      return {
        "height": this.height,
      }
    },
  },
  watch : {
    commentEditContainerContext : function (newCommentEditContext) {
      if (!newCommentEditContext.entry) {
        this.entry = null
        this.text = null
        this.items = []
        return
      }
      this.parentEntryId = newCommentEditContext.parentEntryId
      this.entry = newCommentEditContext.entry
      //// テキストよりメンション文字列（@名前）を作成
      // this.text = mentionUtils.setDestinationIdsToText(newCommentEditContext.entry.text)
      this.text = newCommentEditContext.entry.text
      // メンションに出すアカウント候補取得
      this.items = this.getMentionDestinations(newCommentEditContext)
      // テキストよりグループ文字列（#グループ：１）を作成
      if (newCommentEditContext.entry.entry_division_id == this.Global.entry_division_id.circle 
      || newCommentEditContext.entry_division_id == this.Global.entry_division_id.private_circle) this.text = mentionUtils.setGroupIdsToText(this.text, this.groups)

      if (this.items.length > 0) {
        this.items = this.items.concat(mentionUtils.getHereState())
      }
    },
    text : function () {
      this.resize()
    },
  },

  methods:{
    ...mapMutations([
      'setCommentReloadEntryId',
      'setCommentEditContainerDisplay',
      'initCommentEditContainerContext',
      'setClientErrors'
    ]),
    cancel: function () {
      this.setCommentEditContainerDisplay(false);
      this.initCommentEditContainerContext();
    },
    save: function () {
      this.text = mentionUtils.changeGroupToMembers(this.text, this.groupToAccounts)
      let destinationIds = mentionUtils.getDestinationIdsFromText(this.text, this.accounts, [])
      let destinationGroupIds = mentionUtils.getGroupIdsFromText(this.text, this.groups)
      
      var formData = new FormData()
      formData.append("insert_flg", this.Global.OFF)
      formData.append("id", this.entry.id)
      formData.append("text", mentionUtils.deleteAccountIdFromText(this.text, this.accounts, []))
      formData.append("destination_ids", destinationIds)
      formData.append("destination_group_ids", destinationGroupIds)
      formData.append("all_dest_flg", mentionUtils.getAllDestFlg(this.text))
      formData.append("version_no", this.entry.version_no)

      let ret = entries.tweet(formData)
      ret.then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.text = ""
          this.setCommentReloadEntryId(this.parentEntryId)
          this.setCommentEditContainerDisplay(false)
          this.initCommentEditContainerContext()
          this.$toasted.info("つぶやきました")
          return
        }
        this.$toasted.error("failed")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/comment-edit.vue::save", params: formData, errorMessage:error})
      })
    },
    getMentionDestinations: function (newCommentEditContext) {
      if (newCommentEditContext.entry.entry_division_id == this.Global.entry_division_id.square) {
        this.accounts = this.allAccounts
        return _.map(this.allAccounts, (row) => { return mentionUtils.getMentionStates(row) })
      }
      if (newCommentEditContext.entry.entry_division_id == this.Global.entry_division_id.circle || newCommentEditContext.entry.entry_division_id == this.Global.entry_division_id.private_circle) {
        let filterCircle = _.keyBy(this.circles, 'id')[newCommentEditContext.entry.circle_id]
        this.accounts = filterCircle.members
        this.groups = filterCircle.groups
        this.groupToAccounts = mentionUtils.createGroupMentionToMembersMentionConverter(this.groups)
        let res = _.map(filterCircle.members, r => mentionUtils.getMentionStates(r))
        return res.concat(_.map(filterCircle.groups, r => mentionUtils.getMentionGroupsStates(r)))
      }
      if (newCommentEditContext.entry.entry_division_id == this.Global.entry_division_id.my_space) {
        let _followers = _.map(this.followers, r => r.follow_account)
        this.accounts = _followers
        return _.map(_followers, r => mentionUtils.getMentionStates(r))
      }
      return []
    },
    resize: function () {
      this.height = this.$refs.area.scrollHeight + 'px'
      this.height = "auto"
      this.$nextTick(() => {
        this.height = this.$refs.area.scrollHeight + 'px'
      })
    },
  }
}
</script>