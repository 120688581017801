import Vue from 'vue'
import VueRouter from 'vue-router'
import 'babel-polyfill';
import axios from 'axios'
import moment from 'moment'
import { Mentionable } from 'vue-mention'
import Toasted from 'vue-toasted'
import VTooltip from 'v-tooltip'
import VueLazyLoad from 'vue-lazyload'

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from './store/index.js'
import router from './router/index.js'
import Global from './js/constant.js'
import Common from './js/common.js'
import App from './App.vue'
import Header from './components/common/header'
import Footer from './components/common/footer'
import EntryEdit from './components/common/entry-edit';
import CommentEdit from './components/common/comment-edit';
import AccountAnchorImage from './components/common/image/account-anchor-image'
import AccountImage from './components/common/image/account-image'
import CircleAnchorImage from './components/common/image/circle-anchor-image'
import CircleImage from './components/common/image/circle-image'
import ThumbnailImage from './components/common/image/thumbnail-image.vue'
import ValidationErrors from './components/common/validation-errors'
import SupportNavigation from './components/support/support-navigation'
import PrivacyPolicy from './components/support/privacy-policy'
import VueObserveVisibility from 'vue-observe-visibility'
Object.defineProperty(Vue.prototype, 'axios', { value: axios })
Object.defineProperty(Vue.prototype, 'Global', { value: Global })
Object.defineProperty(Vue.prototype, 'common', { value: Common })

Vue.use(VueRouter)
Vue.use(Toasted, {duration:4000})
Vue.use(VueRouter)
Vue.use(VTooltip)
Vue.use(VueLazyLoad)
Vue.use(VueObserveVisibility)
library.add(far, fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('c-header', Header)
Vue.component('c-footer', Footer)
Vue.component('c-entry-edit', EntryEdit)
Vue.component('c-comment-edit', CommentEdit)
Vue.component('c-account-anchor-image', AccountAnchorImage)
Vue.component('c-account-image', AccountImage)
Vue.component('c-circle-anchor-image', CircleAnchorImage)
Vue.component('c-circle-image', CircleImage)
Vue.component('c-thumbnail-image', ThumbnailImage)
Vue.component('c-validation-errors', ValidationErrors)
Vue.component('c-support-navigation', SupportNavigation)
Vue.component('c-privacy-policy', PrivacyPolicy)
Vue.component('mentionable', Mentionable)

moment.locale('ja')

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router : router,
  store,
  mounted : function() {
    this.$store.commit('setSessionId', document.getElementById('rails_session_id').value)
  },

}).$mount('#app')


//google アナリティクス
var _gaq = _gaq || [];
_gaq.push(['_setAccount', 'UA-32172684-1']);
_gaq.push(['_trackPageview']);

(function() {
var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
})();



