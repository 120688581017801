<template>
  <a href="" @click.prevent="logout()"><font-awesome-icon v-show="icon===true" icon="sign-out-alt" v-tooltip="'ログアウト'" class="header-menu-icon" /><span>ログアウト</span></a>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import auto_logins from '../../../js/api/auto-logins'

export default {
  props:{
    icon: {
      type:Boolean,
      required:true,
    }
  },
  computed: {
    ...mapState([
      'account'
    ]),
  },

  methods:{
    ...mapMutations([
      'initState',
      'setClientErrors',
    ]),
    ...mapActions([
      'save',
    ]),
    logout : function () {
      let ret = auto_logins.delete(this.account.id)
      ret.then(res => {
        if (res.data.status != this.Global.api_status.success) this.setClientErrors({func:"common/header/logout-anchor.vue::logout", params: { account_id : this.account.id }, errorMessage:JSON.stringify(res.data)})
        this.initState()
        this.save()
        this.$toasted.success("see you!")
        this.$router.push({ path : '/square' }, () => {})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/header/logout-anchor.vue::logout", params: {account_id:this.account.id}, errorMessage:error})
      })
    }
  },
}
</script>