<template>
  <footer><a href=""><img src="/assets/logo.svg" alt="ともつくネット"></a>
    <c-footer-navigation :mode="pc" />
    <p>Copyright ©megurinowa.Inc All Rights Reserved.</p>
  </footer>
</template>

<script>
import FooterNavigation from './footer-navigation'

export default {
  computed: {
    pc : function () {
      return this.Global.device_division_id.pc
    },
  },
  components: {
    'c-footer-navigation' : FooterNavigation,
  }
}
</script>