<template>
  <ul class="main" :class="[ {main : mode == pc } ]">
    <li>
      <a href="#" @click.prevent="toSquare">
        <div>ひろば<span v-show = "itsNewSquareNo > 0" :class="[ {notification: mode == pc} ]">{{itsNewSquareNo}}</span></div>
      </a>
    </li>
    <li>
      <a href="#" @click.prevent="toCircle">
        <div>サークル<span v-show = "itsNewCircleNo > 0" :class="[ {notification: mode == pc} ]">{{itsNewCircleNo}}</span></div>
      </a>
    </li>
    <li v-if="Boolean(loginFlg)">
      <a href="#" @click.prevent="toMyspace">
        <div>マイスペース<span v-show = "itsNewMyspaceNo > 0" :class="[ {notification: mode == pc} ]">{{itsNewMyspaceNo}}</span></div>
      </a>
    </li>
  </ul>
</template>
<script>
import{mapState, mapMutations} from 'vuex'
import _ from 'lodash'
import entries from '../../../js/api/entries'

export default {
  props:{
    mode:{
      type:Number,
      required:true
    }
  },
  data () {
    return {
      itsNewSquare: [],
      itsNewCircle: [],
      itsNewMyspace: [],
      itsNewSquareNo: 0,
      itsNewCircleNo: 0,
      itsNewMyspaceNo: 0,
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account'
    ]),
    pc : function () {
      return this.Global.device_division_id.pc
    },

  },
  mounted : function () {
    setInterval(this.getNews, this.Global.header_navi_interval)
  },
  methods : {
    ...mapMutations([
      'setCircleMode',
      'setCircleHashtagDivisionId',
      'setCircleHashtagId',
      'setHashtagDivisionId',
      'setHashtagId',
      'setHashtagIds',
      'setClientErrors'
    ]),
    getNews : function () {
      if (!this.loginFlg) return
      if (!this.account) return

      let ret = entries.itsNew(this.account.id)
      ret.then(res => {
        if ( res.data.status == this.Global.api_status.success) {
          this.itsNewSquareNo = res.data.its_news.square.length
          this.itsNewCircleNo = res.data.its_news.circle.length
          this.itsNewMyspaceNo = res.data.its_news.myspace.length

          if (_.difference(this.itsNewSquare, res.data.its_news.square).length > 0 ) {
            this.$toasted.info("ひろばに新着メッセージがあります")
            this.itsNewSquare = res.data.its_news.square
          }
          if (_.difference(this.itsNewCircle, res.data.its_news.circle).length > 0 ) {
            this.$toasted.info("サークルに新着メッセージがあります")
            this.itsNewCircle = res.data.its_news.circle
          }
          if (_.difference(this.itsNewMyspace, res.data.its_news.myspace).length > 0 ) {
            this.$toasted.info("マイスペースに新着メッセージがあります")
            this.itsNewMyspace = res.data.its_news.myspace
          }
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({
          func:"common/header/header-navigation.vue::getNews",
          params: {account_id : this.account.id},
          errorMessage:error
        })
      })
    },
    toSquare: function () {
      this.setHashtagDivisionId(this.Global.hashtag_division_id.home)
      this.setHashtagId(null)
      this.setHashtagIds([])
      this.$router.push( { path : '/' }, () => {} )
    },
    toCircle: function () {
      this.setCircleMode(this.Global.circle_mode.public)
      this.setCircleHashtagDivisionId(this.Global.hashtag_division_id.home)
      this.setCircleHashtagId(null)
      this.$router.push({ path : '/circle'}, () => {})
    },
    toMyspace: function () {
      this.$router.push({ path : '/myspace/' + this.account.id }, () => {})
    },
  }
}
</script>
<style>
.bage_item {
  margin-top: 10px;
  margin-right: 40px;
}
</style>