<template>
  <div v-show="entryEditContainerDisplay">
    <div class="action-overlay">
      <div class="container">
        <p>投稿を編集する</p>
        <div class="item">
          <!-- <input type="text" placeholder="今の思いを言葉にしてみよう"><span class="focusborder"></span> -->

          <mentionable
            :keys="keys"
            :items="items"
            :limit="mentionLimit"
            insert-space
            @open="onOpen">
            <textarea v-model="text" class="flextextarea-textarea" id="flextextarea" placeholder="ひろばでしゃべりたいことを書く" ref="area" :style="styles"></textarea>
            <template #no-result>
              <div class="dim">
                No result
              </div>
            </template>
            <template #item-@="{ item }">
              <div class="destination" v-if="!item.groupFlg">
                {{ item.lastName + item.firstName }}
                <span class="dim">
                  {{ item.nickname }}
                </span>
              </div>
              <div class="group" v-else>
                {{ item.name }}
                <span class="dim">
                  ({{ item.count }}名)
                </span>
              </div>
            </template>
            <template #item-#="{ item }">
              <div class="issue">
                {{ item.name }}
                <span class="number">
                  {{item.subName}}
                </span>
              </div>
            </template>
          </mentionable>
          <span class="focusborder"></span>

        </div> 
        <div class="action-all">
          <div class="tools">
            <div class="owner" v-show="entryDivisionId == 1">
              <!-- 広場のとき -->
              <span class="balloon">フォロワー限定</span>
              <input type="checkbox" name="train" class="checkbox,owner" id="chuo1" ref="disclosureIconCheckbox1" />
              <label for="chuo1">
                <svg @click="followerOnly" version="1.1" id="icon-owner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
                  <g>
                    <circle class="icon-owner" cx="252.98" cy="135.26" r="103.02"/>
                    <path class="icon-owner" d="M281.12,468.46c0-55.62,14.03-107.96,38.74-153.68c-21.07-3.76-43.53-5.81-66.88-5.81
                      c-119.37,0-216.14,53.08-216.14,118.56v121.93h254.49C284.67,523.56,281.12,496.43,281.12,468.46z"/>
                    <path class="icon-owner" d="M518.17,387.41h-5.32V348.63A78.2,78.2,0,0,0,490,293.89c-13.69-13.69-34.22-22.81-54.75-22.81a76.85,76.85,0,0,0-54.74,22.81c-13.69,13.68-22.81,34.21-22.81,54.74v38.78h-5.32c-19.77,0-34.22,15.21-34.22,34.22v95.8c0,19.77,14.45,34.22,34.22,34.22H518.93c19.77,0,34.22-14.45,34.22-34.22v-95.8A35.21,35.21,0,0,0,518.17,387.41ZM390.43,348.63c0-12.16,4.57-22.81,12.93-30.41,7.6-7.6,19-12.93,30.41-12.93A44.36,44.36,0,0,1,465,318.22a42.63,42.63,0,0,1,12.92,30.41v38.78H388.15V348.63Zm53.23,130.78,6.08,35.74H419.33l6.08-35.74c-7.6-3-15.21-12.92-15.21-22.05a23.69,23.69,0,0,1,23.57-23.57c13.69,0,24.34,11.41,24.34,23.57A21.53,21.53,0,0,1,443.66,479.41Z"/>
                  </g>
                </svg>
              </label>
            </div>
            <!-- サークルの時 -->
            <div class="owner" v-show="entryDivisionId == 2">
              <span class="balloon">サークルとして投稿</span>
              <input type="checkbox" name="train" class="checkbox,owner" id="chuo2" ref="disclosureIconCheckbox2" />
              <label for="chuo2">
                <svg @click="entryAsCircle" version="1.1" id="icon-owner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
                  <g>
                    <circle class="icon-owner" cx="252.98" cy="135.26" r="103.02"/>
                    <path class="icon-owner" d="M281.12,468.46c0-55.62,14.03-107.96,38.74-153.68c-21.07-3.76-43.53-5.81-66.88-5.81
                      c-119.37,0-216.14,53.08-216.14,118.56v121.93h254.49C284.67,523.56,281.12,496.43,281.12,468.46z"/>
                    <path class="icon-owner" d="M559.99,472.88l-25.71-19.46c1.27-8.81,1.27-17.75,0-26.56l25.71-19.46c3.21-2.43,4.11-6.86,2.1-10.35
                      l-23.93-41.45c-2.01-3.49-6.3-4.93-10.01-3.36l-29.73,12.55c-7.02-5.51-14.79-10-23.07-13.34l-3.99-31.95
                      c-0.5-3.99-3.89-6.99-7.91-6.99h-47.86c-4.02,0-7.42,3-7.91,6.99l-3.99,31.95c-8.28,3.34-16.04,7.83-23.07,13.34l-29.73-12.55
                      c-3.71-1.57-8-0.12-10.01,3.36l-23.93,41.45c-2.01,3.48-1.11,7.92,2.1,10.35l25.71,19.46c-1.27,8.81-1.27,17.75,0,26.56
                      l-25.71,19.46c-3.21,2.43-4.11,6.86-2.1,10.35l23.93,41.45c2.01,3.48,6.3,4.93,10.01,3.36l29.73-12.55
                      c7.02,5.51,14.79,10,23.07,13.34l3.99,31.95c0.5,3.99,3.89,6.99,7.91,6.99h47.86c4.02,0,7.42-3,7.91-6.99l3.99-31.95
                      c8.28-3.34,16.04-7.83,23.07-13.34l29.73,12.55c3.71,1.57,8,0.12,10.01-3.36l23.93-41.45C564.1,479.74,563.2,475.3,559.99,472.88z
                      M439.52,472.04c-17.62,0-31.91-14.29-31.91-31.91c0-17.62,14.28-31.91,31.91-31.91c17.62,0,31.91,14.29,31.91,31.91
                      C471.4,457.75,457.13,472.02,439.52,472.04z"/>
                  </g>
                </svg>
              </label>
            </div>
            <!-- マイスペースの時 -->
            <div class="owner" v-show="entryDivisionId == 4">
              <span class="balloon">ひろばに公開</span>
              <input type="checkbox" name="train" class="checkbox,owner" id="chuo4" ref="disclosureIconCheckbox4" />
              <label for="chuo4">
                <svg @click="entryToSuare" version="1.1" id="icon-owner" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 600 600" style="enable-background:new 0 0 600 600;" xml:space="preserve">
                  <g>
                    <circle class="icon-owner" cx="252.98" cy="135.26" r="103.02"/>
                    <path class="icon-owner" d="M281.12,468.46c0-55.62,14.03-107.96,38.74-153.68c-21.07-3.76-43.53-5.81-66.88-5.81
                      c-119.37,0-216.14,53.08-216.14,118.56v121.93h254.49C284.67,523.56,281.12,496.43,281.12,468.46z"/>
                    <path class="icon-owner" d="M518.17,387.41h-5.32V348.63A78.2,78.2,0,0,0,490,293.89c-13.69-13.69-34.22-22.81-54.75-22.81a76.85,76.85,0,0,0-54.74,22.81c-13.69,13.68-22.81,34.21-22.81,54.74v.12h30.41v-.12h2.28c0-12.16,4.57-22.81,12.93-30.41,7.6-7.6,19-12.93,30.41-12.93A44.36,44.36,0,0,1,465,318.22a42.63,42.63,0,0,1,12.92,30.41v38.78H408.11v.15H349.35v0c-18.18,1.49-31.15,16.08-31.15,34.09v95.8c0,19.77,14.45,34.22,34.22,34.22H518.93c19.77,0,34.22-14.45,34.22-34.22v-95.8A35.21,35.21,0,0,0,518.17,387.41Zm-74.51,92,6.08,35.74H419.33l6.08-35.74c-7.6-3-15.21-12.92-15.21-22.05a23.69,23.69,0,0,1,23.57-23.57c13.69,0,24.34,11.41,24.34,23.57A21.53,21.53,0,0,1,443.66,479.41Z"/>
                  </g>
                </svg>
              </label>
          </div>
            <div class="emoji" @click="() => { emojiDialog =! emojiDialog }">
              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="grin-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="svg-inline--fa fa-grin-alt fa-w-16 fa-2x" style="color: rgb(36, 167, 255);"><path fill="currentColor" d="M200.3 248c12.4-18.7 15.1-37.3 15.7-56-.5-18.7-3.3-37.3-15.7-56-8-12-25.1-11.4-32.7 0-12.4 18.7-15.1 37.3-15.7 56 .5 18.7 3.3 37.3 15.7 56 8.1 12 25.2 11.4 32.7 0zm128 0c12.4-18.7 15.1-37.3 15.7-56-.5-18.7-3.3-37.3-15.7-56-8-12-25.1-11.4-32.7 0-12.4 18.7-15.1 37.3-15.7 56 .5 18.7 3.3 37.3 15.7 56 8.1 12 25.2 11.4 32.7 0zM248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm105.6-151.4c-25.9 8.3-64.4 13.1-105.6 13.1s-79.6-4.8-105.6-13.1c-9.9-3.1-19.4 5.3-17.7 15.3 7.9 47.2 71.3 80 123.3 80s115.3-32.9 123.3-80c1.6-9.8-7.7-18.4-17.7-15.3z" class=""></path></svg>
            </div>
            <div class="photo" @click="photoImageClick()">
              <svg version="1.1" id="icon-photo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 28.3 28.3" style="enable-background:new 0 0 28.3 28.3;" xml:space="preserve">
                <path class="st0" id="np_photo_2067966_000000" d="M25.7,7.3h-3.6l-0.6-3.6c-0.1-0.6-0.4-1.2-1-1.5c-0.5-0.4-1.1-0.5-1.7-0.4                    L2.3,4.7C1,4.9,0.2,6.1,0.4,7.4c0,0,0,0,0,0l2.5,14.3c0.1,0.6,0.5,1.1,1,1.5c0.4,0.3,0.9,0.4,1.3,0.4c0.1,0,0.3,0,0.4,0l1-0.2v0.8                    c0,1.3,1,2.3,2.3,2.3h16.7c1.3,0,2.3-1,2.3-2.3V9.7C28,8.4,27,7.4,25.7,7.3C25.7,7.3,25.7,7.3,25.7,7.3L25.7,7.3z M24.3,12.4                    c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5C23.7,10.9,24.3,11.6,24.3,12.4L24.3,12.4z M5.2,21.7                    c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.2-0.2-0.2-0.3L2.3,7.1c0-0.2,0.1-0.4,0.3-0.5c0,0,0,0,0,0L19,3.7h0.1c0.1,0,0.2,0,0.2,0.1                    c0.1,0.1,0.2,0.2,0.2,0.3l0.6,3.3H9c-1.3,0-2.3,1-2.3,2.3v11.8L5.2,21.7z M25.7,24.6H9c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0v-4L14,14                    c0.1-0.2,0.3-0.2,0.5-0.1c0,0,0,0,0.1,0.1l3.4,3.8C18,17.9,18,18,18,18.1l-0.4,2c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1,0,0.1,0l3.4-3.7                    c0.1-0.1,0.2-0.1,0.4,0c0,0,0,0,0,0l4.5,4.9v2.8C26.1,24.4,25.9,24.6,25.7,24.6C25.7,24.6,25.7,24.6,25.7,24.6L25.7,24.6z"></path>
              </svg>
            </div>
          </div>
          <div class="action">  
            <button class="cancel" @click="cancel">キャンセル</button>
            <button class="confirm" @click="save">保存する</button>
          </div>
        </div>
        <c-emoji-dialog v-show="emojiDialog" @add-emoji="(val) => { addEmoji(val) }" />
        <div v-if="entry != null && Boolean(entry.has_thumbnail)">
          <c-thumbnail-image :entry-id="entry.id" />
        </div>
        <input @change="attachImg" type="file" class="hide-photo" :model="img" ref="hide_photo" />
        <div v-show="resizedImg" class="resize-img__preview">
          <div class="resize-img__preview__circle" @click="clearAttachImg">
            <img :src="resizedImg" class="resize-img__preview__img">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.hide-photo {
  display: none;
}
</style>
<script>
import { mapState, mapMutations } from 'vuex'
import loadImage from 'blueimp-load-image'
import base64ToBlob from 'b64-to-blob'
import mentionUtils from '../../js/common/entry/MentionUtils';
import EmojiDialog from './emoji-dialog'
import _ from 'lodash'
import entries from '../../js/api/entries';

export default {
  data () {
    return {
      entry: null,
      entryDivisionId: null,
      text: null,
      mentionDestinations: null,
      mentionHashtags: null,
      keys : ['@', '#'],
      mentionLimit : this.Global.vue_mention_limit,
      items : [],
      accounts: [],
      groups: [],
      groupToAccounts: {},
      localHashtags: [],
      emojiDialog: false,
      img: null,
      thumbnail: null,
      resizedImg:[],
      officialRange: null,
      entryAsCircleFlg: false,
      followerOnlyFlg: false,
      entryToSquareFlg: false,
      height: '90px',
    }
  },
  computed : {
    ...mapState([
      'loginFlg',
      'account',
      'entryEditContainerDisplay',
      'entryEditContainerContext',
      'allAccounts',
      'circles',
      'followers',
      'hashtags',
    ]),
    styles() {
      return {
        "height": this.height,
      }
    },
  },
  watch : {
    entryEditContainerContext : function (newEntryEditContext) {
      if (!newEntryEditContext.entry) {
        this.entry = null
        this.entryDivisionId = null
        this.text = null
        this.items = []
        return
      }
      // メンションに出すアカウント候補取得
      this.mentionDestinations = this.getMentionDestinations(newEntryEditContext)
      // メンションに出すハッシュタグ候補取得
      this.mentionHashtags = this.getMentionHashtags(newEntryEditContext)
      this.entry = newEntryEditContext.entry
      this.entryDivisionId = newEntryEditContext.entry.entry_division_id
      // // テキストよりメンション文字列（@名前）を作成
      // let t = mentionUtils.setDestinationIdsToText(newEntryEditContext.entry.text)
      // テキストよりハッシュタグ文字列（#タグ：１）を作成
      this.text = newEntryEditContext.entry.text
      // this.text = mentionUtils.setHashtagIdsToText(newEntryEditContext.entry.entry_division_id, newEntryEditContext.entry.text, _.map(newEntryEditContext.entry.hashtags, row => { return { id: row.mst_topic_id, name: row.topic_name, official: row.official,}}))
      // テキストよりグループ文字列（#グループ：１）を作成
      if (newEntryEditContext.entry.entry_division_id == this.Global.entry_division_id.circle 
          || newEntryEditContext.entry_division_id == this.Global.entry_division_id.private_circle) this.text = mentionUtils.setGroupIdsToText(this.text, this.groups)
      if (newEntryEditContext.entry.entry_division_id === this.Global.entry_division_id.myspace) this.keys = ['@'] 

      if (this.mentionDestinations.length > 0) {
        this.mentionDestinations = this.mentionDestinations.concat(mentionUtils.getHereState())
      }
      // つぶやき範囲ボタンの制御
      this.setDisclosureInformatons(newEntryEditContext)
    },
    text : function () {
      this.resize()
    },
  },
  methods:{
    ...mapMutations([
      'setEntryReloadFlg',
      'setEntryReloadId',
      'setEntryEditContainerDisplay',
      'setEntryEditContainerContext',
      'initEntryEditContainerContext',
      'setClientErrors'
    ]),
    cancel: function () {
      this.setEntryEditContainerDisplay(false);
      this.initEntryEditContainerContext();
    },
    save: function () {

      if (mentionUtils.isEpty(this.text, this.accounts, this.groups, this.hashtags.square)) {
        this.$toasted.error("no tweet")
        return
      }
      this.text = mentionUtils.changeGroupToMembers(this.text, this.groupToAccounts)
      let destinationIds = mentionUtils.getDestinationIdsFromText(this.text, this.accounts, [])
      let hashtagIds = mentionUtils.getHashtagIdsFromText(this.entry.entry_division_id, this.text, this.localHashtags)
      let destinationGroupIds = mentionUtils.getGroupIdsFromText(this.text, this.groups)
      this.text = mentionUtils.deleteAccountIdFromText(this.text, this.accounts, [])
      // this.text = mentionUtils.deleteHashtagIdFromText(this.entry.entry_division_id, this.text, this.localHashtags)

      var formData = new FormData()
      formData.append("insert_flg", this.Global.OFF)
      formData.append("id", this.entry.id)
      formData.append("text", this.text)
      formData.append("entry_division_id", this.getEngryDivisionIdByDiclosureFlg())
      formData.append("destination_ids", destinationIds)
      formData.append("hashtag_ids", hashtagIds)
      formData.append("destination_group_ids", destinationGroupIds)
      formData.append("all_dest_flg", mentionUtils.getAllDestFlg(this.text))
      formData.append("img", this.img ? this.img : '')
      formData.append("thumbnail", this.thumbnail ? this.thumbnail : '')
      if(this.entryAsCircleFlg) formData.append("entry_as_circle", true)
      if(this.officialRange != null) formData.append("official_range", this.officialRange)
      formData.append("version_no", this.entry.version_no)

      let ret = entries.tweet(formData)
      ret.then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.text = ""
          this.hashtagIds = []
          this.destinationIds = []
          this.clearAttachImg()
          this.setEntryReloadFlg(true)
          this.setEntryReloadId(this.entry.id)

          this.$refs.disclosureIconCheckbox1.checked = false
          this.$refs.disclosureIconCheckbox2.checked = false
          this.$refs.disclosureIconCheckbox4.checked = false
          this.$toasted.info("つぶやき更新しました")
          this.setEntryEditContainerDisplay(false)
          this.setEntryEditContainerContext({
            entry: null
          })
          return
        }
        this.$toasted.error("failed")
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry-edit.vue::save", params: formData, errorMessage:error})
      })
    },
    onOpen:function (key) {
      if (this.entryDivisionId === this.Global.entry_division_id.myspace) {
        this.items = this.mentionDestinations
        return
      }
      this.items = key === '@' ? this.mentionDestinations : this.mentionHashtags
    },
    getMentionDestinations: function (newEntryEditContext) {
      if (newEntryEditContext.entry.entry_division_id == this.Global.entry_division_id.square) {
        this.accounts = this.allAccounts
        return _.map(this.allAccounts, (row) => { return mentionUtils.getMentionStates(row) })
      }
      if (newEntryEditContext.entry.entry_division_id == this.Global.entry_division_id.circle || newEntryEditContext.entry_division_id == this.Global.entry_division_id.private_circle) {
        let filterCircle = _.keyBy(this.circles, 'id')[newEntryEditContext.entry.circle_id]
        this.accounts = filterCircle.members
        this.groups = filterCircle.groups
        this.groupToAccounts = mentionUtils.createGroupMentionToMembersMentionConverter(filterCircle.groups)
        let res = _.map(filterCircle.members, r => mentionUtils.getMentionStates(r))
        return res.concat(_.map(filterCircle.groups, r => mentionUtils.getMentionGroupsStates(r)))
      }
      if (newEntryEditContext.entry.entry_division_id == this.Global.entry_division_id.my_space) {
        let _followers = _.map(this.followers, r => r.follow_account)
        this.accounts = _followers
        return _.map(_followers, r => mentionUtils.getMentionStates(r))
      }
      return []
    },
    getMentionHashtags: function (newEntryEditContext) {
      if (newEntryEditContext.entry.entry_division_id == this.Global.entry_division_id.square) {
        this.localHashtags = this.hashtags.square
        return _.map(this.hashtags.square, (row) => { return mentionUtils.getMentionHashtagStates(newEntryEditContext.entry.entry_division_id, row) })
      }
      if (newEntryEditContext.entry.entry_division_id == this.Global.entry_division_id.circle || newEntryEditContext.entry_division_id == this.Global.entry_division_id.private_circle) {
        let filterCircle = _.keyBy(this.circles, 'id')[newEntryEditContext.entry.circle_id]
        this.localHashtags = filterCircle.hashtags
        return _.map(filterCircle.hashtags, (row) => mentionUtils.getMentionHashtagStates(newEntryEditContext.entry.entry_division_id, row))
      }
      return []
    },
    addEmoji : function (val) {
      if (val) {
        this.text = this.text + val
      }
      this.emojiDialog = false
    },
    attachImg: function (e) {
      const file = e.target.files[0];
      if (!file) {
        return
      }
      // preview
      this.preview(file, this.Global.display_image_max_size)

      // resize  
      const reader = new FileReader()

      reader.onload = (event) => {
        const image = new Image()
        image.crossOrigin = 'Anonymous'        
        image.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const drawImageArgs = this.drawImageArgs(image, this.Global.updaload_image_max_size)
          canvas.width = drawImageArgs[7]
          canvas.height = drawImageArgs[8]
          ctx.drawImage(...drawImageArgs)
          const base64 = canvas.toDataURL()
          this.img = base64ToBlob(base64.replace(/^.*,/, ''))
          this.entry.has_img = false
        }
        image.src = event.target.result

        const imageThumbnail = new Image()
        imageThumbnail.crossOrigin = 'Anonymous'
        imageThumbnail.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          const drawImageArgs = this.drawImageArgs(imageThumbnail, this.Global.updaload_thumbnail_max_size)
          canvas.width = drawImageArgs[7]
          canvas.height = drawImageArgs[8]
          ctx.drawImage(...drawImageArgs)
          const base64 = canvas.toDataURL()
          this.thumbnail = base64ToBlob(base64.replace(/^.*,/, ''))
          this.entry.has_thumbnail = false
        }
        imageThumbnail.src = event.target.result
      }

      reader.readAsDataURL(file)
    },
    preview (file, maxSize) {
      loadImage.parseMetaData(file, (data) => {
        const options = {
          maxHeight: maxSize,
          maxWidth: maxSize,
          canvas: true
        }
        if (data.exif) {
          options.orientation = data.exif.get('Orientation');
        }
        this.displayImage(file, options)
      })
    },
    displayImage(file, options) {
      loadImage(
        file,
        async (canvas) => {
          const data = canvas.toDataURL(file.type)
          // data_url形式をblob objectに変換
          const blob = this.base64ToBlob(data, file.type)
          // objectのURLを生成
          const url = window.URL.createObjectURL(blob)
          this.resizedImg = url;
        },
        options
      )
    },
    drawImageArgs (image, maxSize) {
      let sx = 0
      let sy = 0
      let dx = 0
      let dy = 0
      let imageWidth = image.width
      let imageHeight = image.height

      let ratio = 1
      if (imageWidth > imageHeight && imageWidth > maxSize) {
        ratio = maxSize/imageWidth
      } else if(imageWidth < imageHeight && imageHeight > maxSize) {
        ratio = maxSize/imageHeight
      }
      const dstWidth = imageWidth * ratio
      const dstHeight = imageHeight * ratio

      return [image, sx, sy, imageWidth, imageHeight, dx, dy, dstWidth, dstHeight]
    },

    photoImageClick : function () {
      this.$refs.hide_photo.click()
    },
    clearAttachImg() {
      this.resizedImg = null;
      if (this.$refs.fileInput && this.$refs.fileInput.value !== undefined) {
        this.$refs.fileInput.value = '';
        window.URL.revokeObjectURL(this.resizedImg);
      }
    },
    base64ToBlob(base64, fileType) {
      const bin = atob(base64.replace(/^.*,/, ''));
      const buffer = new Uint8Array(bin.length);
      for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      return new Blob([buffer.buffer], {
        type: fileType ? fileType : 'image/png'
      });
    },
    mail : function (entryId) {
      let params = { entry_id: entryId }
      // this.axios.post(this.Global.url.api_entries_email, params)
      // .then(res => {
      //   if (res.data.status === this.Global.api_status.success) {
      //     return
      //   }
      //   this.setClientErrors({func:"common/entry-edit.vue::mail", params: params, errorMessage:JSON.stringify(res.data)})
      // })
      // .catch(error=>{
      //   this.$toasted.error('client error')
      //   this.setClientErrors({func:"common/entry-edit.vue::mail", params: params, errorMessage:error})
      // })
      alert('463')
      let ret = entries.email(entryId)
      ret.then(res => {
        if (res.data.status === this.Global.api_status.success) {
          return
        }
        this.setClientErrors({func:"common/entry-edit.vue::mail", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/entry-edit.vue::mail", params: params, errorMessage:error})
      })
    },
    entryAsCircle: function () {
      this.entryAsCircleFlg = !this.entryAsCircleFlg
      this.officialRange = this.entryAsCircleFlg ? this.Global.entry_division_id.square : null
    },
    entryToSuare: function () {
      this.entryToSquareFlg = !this.entryToSquareFlg
      if (this.officialRange != null) this.officialRange = null
      if (this.officialRange == null) this.officialRange = this.Global.entry_division_id.square
    },
    followerOnly: function () {
      this.followerOnlyFlg = !this.followerOnlyFlg
      this.officialRange = this.followerOnlyFlg ? this.Global.entry_division_id.myspace : null
    },
    setDisclosureInformatons: function (context) {
      if (context.entry.entry_division_id === this.Global.entry_division_id.square) {
        this.followerOnlyFlg = context.entry.official_range === this.Global.entry_division_id.myspace ? true : false
        this.$refs.disclosureIconCheckbox1.checked = this.followerOnlyFlg
      } else if (context.entry.entry_division_id === this.Global.entry_division_id.circle || context.entry.entry_division_id === this.Global.entry_division_id.private_circle) {
        this.entryAsCircleFlg = context.entry.entry_as_circle
        this.$refs.disclosureIconCheckbox2.checked = this.entryAsCircleFlg
      } else if (context.entry.entry_division_id === this.Global.entry_division_id.myspace) {
        this.entryToSquareFlg = context.entry.official_range === this.Global.entry_division_id.square ? true : false
        this.$refs.disclosureIconCheckbox4.checked = this.entryToSquareFlg
      }
      this.officialRange = context.entry.official_range
    },
    getEngryDivisionIdByDiclosureFlg: function () {
      return this.entryDivisionId
    },
    resize: function () {
      this.height = this.$refs.area.scrollHeight + 'px'
      this.height = "auto"
      this.$nextTick(() => {
        this.height = this.$refs.area.scrollHeight + 'px'
      })
    },
  },
  components: {
    'c-emoji-dialog': EmojiDialog,
  }
}
</script>