var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"tabbar"},[_c('nav',[_c('c-header-navigation',{attrs:{"mode":_vm.mobile}})],1)]),_c('div',{staticClass:"navbar"},[_c('input',{attrs:{"id":"navinput","type":"checkbox"}}),_c('label',{attrs:{"for":"navinput","id":"navclose"}}),_c('nav',[_c('c-logo',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: '広場へ',
          autoHide:false,
          loadingContent: 'Please wait...',
          loadingClass: 'content-is-loading',
      }),expression:"{\n          content: '広場へ',\n          autoHide:false,\n          loadingContent: 'Please wait...',\n          loadingClass: 'content-is-loading',\n      }"}]}),(Boolean(_vm.loginFlg))?_c('c-account-info-mobile'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!Boolean(_vm.loginFlg)),expression:"!Boolean(loginFlg)"}],staticClass:"user guest"},[_vm._m(0),_vm._m(1)]),_c('c-header-navigation',{attrs:{"mode":_vm.pc}}),_c('c-footer-navigation',{attrs:{"mode":_vm.mobile}})],1),_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":"/assets/logo.svg","alt":"ともつくネット"}})])],1),(_vm.account != null)?_c('div',{staticClass:"notification"},[_c('input',{attrs:{"id":"notificationinput","type":"checkbox"},domProps:{"checked":_vm.notificationContainerDisplay}}),_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: 'お知らせ',
          autoHide:false,
          loadingContent: 'Please wait...',
          loadingClass: 'content-is-loading',
        }),expression:"{\n          content: 'お知らせ',\n          autoHide:false,\n          loadingContent: 'Please wait...',\n          loadingClass: 'content-is-loading',\n        }"}],attrs:{"for":"notificationinput","id":"notificationclose"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.notificationCount > 0),expression:"notificationCount > 0"}]},[_vm._v(_vm._s(_vm.notificationCount))]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleNotificationchecked($event)}}},[_c('img',{attrs:{"src":"/assets/icon-notification.svg","alt":"あなたへの通知"}})])]),_c('c-notification',{attrs:{"account":_vm.account}})],1):_vm._e(),_c('div',{staticClass:"tool"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'キーワード検索',
        autoHide:false,
        loadingContent: 'Please wait...',
        loadingClass: 'content-is-loading',
      }),expression:"{\n        content: 'キーワード検索',\n        autoHide:false,\n        loadingContent: 'Please wait...',\n        loadingClass: 'content-is-loading',\n      }"}],staticClass:"search"},[_c('input',{attrs:{"id":"searchinput","type":"checkbox"}}),_c('label',{attrs:{"for":"searchinput","id":"searchclose"},on:{"click":_vm.toggleSearchContainerDisplay}},[_c('img',{attrs:{"src":"/assets/icon-search.svg","alt":"投稿を検索"}})])]),_c('c-keyword-search',{directives:[{name:"show",rawName:"v-show",value:(_vm.keywordSearchContainerDisplay),expression:"keywordSearchContainerDisplay"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!Boolean(_vm.loginFlg)),expression:"!Boolean(loginFlg)"}],attrs:{"id":"drawer-form"}},[_c('input',{attrs:{"type":"radio","name":"drawer","id":"login-drawer"}}),_c('input',{attrs:{"type":"radio","name":"drawer","id":"signin-drawer"}}),_c('label',{staticClass:"login",attrs:{"for":"login-drawer"}},[_vm._v("ログイン")]),_c('label',{staticClass:"signin",attrs:{"for":"signin-drawer"}},[_vm._v("新規登録")]),_c('c-temporary-sign-in'),_c('c-login')],1),(Boolean(_vm.loginFlg))?_c('c-account-info-pc',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.account.last_name + _vm.account.first_name,
          autoHide:true,
          loadingContent: 'Please wait...',
          loadingClass: 'content-is-loading',
        }),expression:"{\n          content: account.last_name + account.first_name,\n          autoHide:true,\n          loadingContent: 'Please wait...',\n          loadingClass: 'content-is-loading',\n        }"}]}):_vm._e()],1)]),_c('div',{staticClass:"brank"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cover"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":"/assets/icon-guest.svg","alt":"ゲスト"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status"},[_c('div',{staticClass:"name"},[_vm._v("ゲストさん")]),_c('div',{staticClass:"action"},[_c('label',{staticClass:"login",attrs:{"for":"login-drawer"}},[_vm._v("ログイン")]),_c('label',{staticClass:"signin",attrs:{"for":"signin-drawer"}},[_vm._v("新規登録")])])])}]

export { render, staticRenderFns }