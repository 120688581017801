<template>
  <div class="container container-login">
    <div class="wrap">
      <input type="radio" name="drawer" id="close-drawer">
      <label class="close" for="close-drawer">閉じる</label>
      <div class="login">
        <section class="detail">
          <h2>ログイン</h2>
          <p>ともつくネットへログイン!</p>
          <p class="recommend">または
            <label class="signin" for="signin-drawer">アカウントを作成</label>
          </p>
          <c-validation-errors :errors-hash="errors" />
          <div class="form">
            <div class="group"></div>
            <div class="item">
              <input type="text" placeholder="メールアドレス" v-model="mail"><span class="focusborder"></span>
            </div>
            <div class="item">
              <input type="password" placeholder="パスワード" v-model="password" @change="clearMessage"><span class="focusborder"></span>
            </div>
            <div class="item submit">
              <input class="button-blue" type="submit" value="設定を保存" @click="authenticate()">
            </div>
            <p class="notice">アカウントを忘れてしまいましたか？<router-link to="/reissue-password">こちら</router-link>よりお手続きできます。</p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// ログインコンポーネント(ヘッダー)
import { mapMutations, mapActions } from 'vuex'
import Common from '../../../js/common'
import accounts from '../../../js/api/accounts'
import auto_logins from '../../../js/api/auto-logins'

export default {

  data () {
    return {
      mail : "",
      password : "",
      errors : {},
    }
  },
  methods: {
    // store.jsに定義されているミューテーションをローカルで簡単にアクセスできるように設定
    ...mapMutations([ 
      'setLoginFlg', 
      'setAutoId',
      'setHost',
      'setAccount',
      'setMail',
      'setClientErrors',
    ]),

    // store.jsに定義されているアクションをローカルでthis.xxxx()でアクセスできるように設定
    ...mapActions([
      'save'
    ]),

    authenticate : function() {
      let ret = accounts.authenticate(this.mail, this.password)
      ret.then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.deleteAutoLogin(res.data.account)
          this.$toasted.success('Hi. ' + res.data.account.nickname + ".")
          this.$router.push({ path : '/myspace/' + res.data.account.id }, () => {})
          return
        }

        if (res.data.status == this.Global.api_status.validation_error) {
          this.errors = res.data.errors
          return
        }
        this.$toasted.error(res.data.exception)
        this.setClientErrors({func:"login.vue::authenticate", params: {}, errorMessage:JSON.stringify(res.data)})

      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"login.vue::authenticate", params: {}, errorMessage:error})
      })
    },

    deleteAutoLogin : function(account) {
      let ret = auto_logins.delete(account.id)
      ret.then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.createAutoLogin(account)
        }        
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/header/login.vue::deleteAutoLogin", params: {accountId: account.id}, errorMessage:error})
      })
    },

    createAutoLogin : function(account) {
      let ret = auto_logins.create(account.id, this.$store.state.sessionId)
      ret.then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.setAutoId(res.data.auto_login.auto_id)
          this.setHost(Common.getUrlHost())
          this.setAccount(account)
          this.setMail(this.mail)
          this.setLoginFlg(true)
          this.save()
          return
        }
        this.$toasted.error('オートログイン処理失敗')
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/header/login.vue::createAutoLogin", params: { account_id : account.id, session_id : this.$store.state.sessionId }, errorMessage:error})
      })
    },
    clearMessage : function() {
      this.errors = {}
    }
  } 
}
</script>
