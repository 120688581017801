<template>
  <p v-if="errors.length">
    <ul>
      <li v-for="(error, index) in errors" :key="index" class="error_li">{{ error }}</li>
    </ul>
  </p>
</template>
<script>
// バリデーションエラー表示コンポーネント
import ErrorHandler from '../../js/error-handler.js'

export default {
  props:{
    errorsHash : {
      type : Object,
      required : true
    }
  },
  computed: {
    errors : function () {
      return ErrorHandler.errorsToArray(this.errorsHash)
    }
  },
  data () {
    return {
      // errors: [],
    }
  },
  methods: {
    registTemporaryAccount : function() {}
  } 
}
</script>
<style>
.error_li {
  color: #ff0000
}
</style>
