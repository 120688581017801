<template>
  <header>

    <div class="tabbar">
      <nav>
        <!-- ヘッダーナビゲーション（mobile） -->
        <c-header-navigation :mode="mobile" />
      </nav>
    </div>

    <div class="navbar">
      <input id="navinput" type="checkbox">
      <label for="navinput" id="navclose"></label>
      <nav>
        <!-- headerロゴ -->
        <c-logo 
          v-tooltip="{
            content: '広場へ',
            autoHide:false,
            loadingContent: 'Please wait...',
            loadingClass: 'content-is-loading',
        }"
        />
        <!-- アカウント情報(mobile) -->
        <c-account-info-mobile v-if="Boolean(loginFlg)" />

        <!-- ゲスト情報(mobile) -->
        <div class="user guest" v-show="!Boolean(loginFlg)">
          <div class="cover">
            <div class="image"><img src="/assets/icon-guest.svg" alt="ゲスト"></div>
          </div>
          <div class="status">
            <div class="name">ゲストさん</div>
            <div class="action">
              <label class="login" for="login-drawer">ログイン</label>
              <label class="signin" for="signin-drawer">新規登録</label>
            </div>
          </div>
        </div>
        
        <!-- ヘッダーナビゲーション（PC） -->
        <c-header-navigation :mode="pc" />

        <!-- フッターナビゲーション（mobile） -->
        <c-footer-navigation :mode="mobile" />

      </nav>

      <div class="logo"><router-link to="/"><img src="/assets/logo.svg" alt="ともつくネット"></router-link></div>

      <!-- お知らせ（吹き出し） -->
      <div class="notification" v-if="account != null">
        <input id="notificationinput" type="checkbox" :checked="notificationContainerDisplay">
        <label for="notificationinput" id="notificationclose"
          v-tooltip="{
            content: 'お知らせ',
            autoHide:false,
            loadingContent: 'Please wait...',
            loadingClass: 'content-is-loading',
          }"><span v-show="notificationCount > 0">{{notificationCount}}</span><a href="#" @click.prevent="toggleNotificationchecked" ><img src="/assets/icon-notification.svg" alt="あなたへの通知"></a></label>
        <c-notification :account="account" />
      </div>

      <div class="tool">
        <div class="search"
          v-tooltip="{
          content: 'キーワード検索',
          autoHide:false,
          loadingContent: 'Please wait...',
          loadingClass: 'content-is-loading',
        }"
        >
          <input id="searchinput" type="checkbox">
          <label for="searchinput" id="searchclose" @click="toggleSearchContainerDisplay"><img src="/assets/icon-search.svg" alt="投稿を検索"></label>
        </div>
        
        <!-- <c-keyword-search v-show="keywordSearchDisplay" /> -->
        <c-keyword-search v-show="keywordSearchContainerDisplay" />

        <div id="drawer-form" v-show="!Boolean(loginFlg)">
          <input type="radio" name="drawer" id="login-drawer">
          <input type="radio" name="drawer" id="signin-drawer">
          <label class="login" for="login-drawer">ログイン</label>
          <label class="signin" for="signin-drawer">新規登録</label>
          <c-temporary-sign-in></c-temporary-sign-in>
          <c-login></c-login>
        </div>
        <!-- アカウント情報（PC） -->
        <c-account-info-pc v-if="Boolean(loginFlg)" 
          v-tooltip="{
            content: account.last_name + account.first_name,
            autoHide:true,
            loadingContent: 'Please wait...',
            loadingClass: 'content-is-loading',
          }"
        />
      </div>

    </div>

    <div class="brank"></div>
  </header>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import _ from 'lodash'
import Logo from './logo'
import AccountInfoMobile from './mobile/account-info'
import HeaderNavigation from './header-navigation.vue'
import FooterNavigation from '../footer/footer-navigation'
import Notification from '../notifications'
import KeywordSearch from '../../keyword-search/search.vue'
import Login from './login.vue'
import TemporarySignIn from '../../registration/temporary-sign-in.vue'
import AccountInfoPC from './pc/account-info'

import Common from '../../../js/common'
import api_entries from '../../../js/api/entries'
import api_auto_logins from '../../../js/api/auto-logins'

export default {
  data () {
    return {
      notificationCount : 0,
    }
  },
  computed: {
    ...mapState([// storeのステートであるloginFlgをthis.loginFlgで呼べるようにマッピング
      'loginFlg',
      'account',
      'temporaryAccount',
      'keywordSearchContainerDisplay',
      'notificationContainerDisplay',
      'clientErrors'
    ]),
    pc : function () {
      return this.Global.device_division_id.pc
    },
    mobile : function () {
      return this.Global.device_division_id.mobile
    },
  },
  watch : {
    loginFlg: function (val) {
      if (!val) this.notificationCount = 0
    },
    temporaryAccount: function (newValue) {
      if (newValue != null) {
        this.getNotificationCountCaseNologin()
      }
    },
    account:function (newAccount) {
      if (newAccount) {
        this.notificationCount = 0
        this.getNotificationCount()
      }
      this.getMyCircles()
      this.getCircles()
      this.getAccountFollows()
      this.getCircleFollows()
      this.getFollowers()
      this.getReferCircles()
    },
    clientErrors : function () {
      if (this.clientErrors != null) this.sendErrors()
    }
  },
  created : function () {
    // データを復元
    this.restore()
  },
  mounted : function() {
    if (this.$store.state.host === Common.getUrlHost()) {
      // 自動ログイン処理
      if (this.$store.state.autoId) {
        // auto_loginテーブル検索
        this.searchAutoLogin()
        return
      }
    }
    console.log("セッションなし")
  },
  methods:{
    ...mapMutations([
      'setLoginFlg',
      'setKeywordSearchContainerDisplay',
      'setNotificationContainerDisplay',
      'setClientErrors',
      ]),
    ...mapActions([
      'save',
      'restore',
      'getMyCircles',
      'getAccountFollows',
      'getCircleFollows',
      'getCircles',
      'getFollowers',
      'getReferCircles',
    ]),
    searchAutoLogin : function() {
      let ret = api_auto_logins.search(this.$store.state.account.id)
      ret.then(result => {
        if (result.data.status === this.Global.api_status.success) {
          if (result.data.auto_login.auto_id == this.$store.state.autoId) {
            console.log("auto_id 一致")
            this.$store.commit('setLoginFlg', true)
            return
          }
          console.log("auto_id 一致しない")
        }
      })
      .catch(error => {
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/header/main.vue::searchAutoLogin", params: {}, errorMessage:error})        
      })
    },
    getNotificationCountCaseNologin : function () {
      if (this.temporaryAccount != null) {
        this.notificationCount = 1
      }
    },
    getNotificationCount : function () {
      let ret = api_entries.notifications(this.account.id, null, 0)
      ret.then(res => {
        if (res.data.status == this.Global.api_status.success) {
          this.notificationCount = _.filter(res.data.entries, (row) => {return row.is_unread}).length
          return
        }
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"common/header/main.vue::getNotificationCount", params: {}, errorMessage:error})
      })
    },
    toggleSearchContainerDisplay : function () {
      this.setKeywordSearchContainerDisplay(!this.keywordSearchContainerDisplay)
    },
    toggleNotificationchecked : function () {
      this.setNotificationContainerDisplay(!this.notificationContainerDisplay)
    },
    sendErrors : function () {
      this.axios.post(this.Global.url.api_client_errors_modify, {
        function:this.clientErrors.func,
        parameters:JSON.stringify(this.clientErrors.params),
        error_message: "" + this.clientErrors.errorMessage
      }).then(res=>{
        if (res.data.status === this.Global.api_status.success) {
          this.setClientErrors(null)
        }
      })
    }
  },
  components: {
    'c-logo' : Logo,
    'c-account-info-mobile' : AccountInfoMobile,
    'c-header-navigation' : HeaderNavigation,
    'c-footer-navigation' : FooterNavigation,
    'c-notification' : Notification,
    'c-login' : Login,
    'c-temporary-sign-in' : TemporarySignIn,
    'c-keyword-search' : KeywordSearch,
    'c-account-info-pc' : AccountInfoPC,
  }
}
</script>
