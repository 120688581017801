<template>
  <div class="user login">
    <div class="cover">
      <div class="image">
        <c-account-image :has-img = "account.has_img" :account-id = "account.id" :account-name = "account.last_name + account.first_name" />
      </div>
    </div>
    <div class="status">
      <div class="name">{{account.nickname}}さん</div>
      <div class="action">
        <router-link to = "/admin/menu" v-if="loginFlg && account.admin_flg">管理メニュー</router-link>
        <router-link to="/settings">設定</router-link>
        <c-logout-anchor :icon="false" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import LogoutAnchor from '../logout-anchor'

export default {
  computed: {
    ...mapState([
      'loginFlg',
      'account'
    ]),
  },
  components : {
    'c-logout-anchor' : LogoutAnchor,
  }
}
</script>