import Global from '../constant'
import axios from 'axios'
var accounts = {
  all: async function() {
    alert(Global.url.api_accounts_all)
  },
  show: async function() {
    alert(Global.url.api_accounts_show)
    alert(axios)
  },
  update: async function() {
    alert(Global.url.api_accounts_update)
  },
  exists: async function() {
    alert(Global.url.api_accounts_exists)
  },
  delete: async function() {
    alert(Global.url.api_account_follows_delete)
  },
  authenticate: async function(mail, password) {
    let params = {}
    if (mail) params['mail'] = mail
    if (password) params['password'] = password
    return await axios.post(Global.url.api_accounts_authenticate, params)
  },

  signIn: async function() {
    alert(Global.url.api_accounts_sign_in)
  },
  leaveOut: async function() {
    alert(Global.url.api_accounts_leave_out)
  },
  reissuePassword: async function() {
    alert(Global.url.api_accounts_reissue_password)
  },

}

export default accounts