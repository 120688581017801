<template>
  <router-link v-if="account != null" :to = "{ name:'myspace', params:{ accountId: accountId} }">
    <img v-if="accountId != null && Boolean(hasImg)" :src="imgUrl" :alt="accountName">
    <img v-else-if="accountId != null && !Boolean(hasImg)" :src="noUserIcon" :alt="accountName">
  </router-link>
  <div v-else>
    <img v-if="accountId != null && Boolean(hasImg)" :src="imgUrl" :alt="accountName">
    <img v-else-if="accountId != null && !Boolean(hasImg)" :src="noUserIcon" :alt="accountName">
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Common from '../../../js/common.js'

export default {
  props : {
    hasImg : {
      type : Boolean,
      required: true
    },
    accountId : {
      type : Number,
      required : false
    },
    accountName : {
      type : String,
      required : false
    },
  },
  computed : {
    ...mapState([
      'account',
    ]),
    imgUrl () {   return '/app/images/account/' + this.accountId },
    noUserIcon () { return Common.getBaseUrl() + this.Global.static_path + '/images/common/user_icon.png' }
  },
}
</script>