import Global from '../constant'
import axios from 'axios'

var auto_logins = {
  search: async function(accountId) {
    let params = {account_id: accountId}
    return await axios.post(Global.url.api_auto_logins_search, params)
  },

  create: async function(accountId, sessionId) {
    let params = { account_id : accountId, session_id : sessionId }
    return await axios.post(Global.url.api_auto_logins_create, params)
  },

  delete: async function(accountId) {
    let params = { account_id : accountId }
    return await axios.post(Global.url.api_auto_logins_delete, params)
  },
}

export default auto_logins