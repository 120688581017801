<template>
    <div>
      <img :src="imgUrl" @click="show()" class="thumbnail" />
      <light-box
        ref="lightbox"
        :media="images"
        :images="images"
        :show-light-box="false"
        :show-caption="false"></light-box>
    </div>
</template>

<script>
import LightBox from 'vue-image-lightbox'
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  props : {
    entryId : {
      type : Number,
      required : false
    },
  },
  data () {
    return {
      images:[
        {
          thumb:'/app/images/entry/thumbnail/' + this.entryId,
          src:'/app/images/entry/' + this.entryId,
        }
      ]
    }
  },
  watch:{
  },
  computed : {
    imgUrl () {   return '/app/images/entry/thumbnail/' + this.entryId },
  },
  methods: {
    show: function(){
      this.$refs.lightbox.showImage(0)
    }
  },
  components:{
    LightBox,
  }
}
</script>