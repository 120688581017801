<template>
  <div id="app">
    <c-header />
    <c-entry-edit />
    <c-comment-edit />
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- ルートアウトレット -->
    <!-- ルートとマッチしたコンポーネントがここへ描画されます -->
    <router-view />
    <c-footer />
    <div class="Page-Btn" @click="scrollTop">
      <i class="fas fa-chevron-up Page-Btn-Icon"></i>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  created : function () {
    this.getAllAccounts()
    this.getCircles()
  },
  methods: {
    ...mapActions([
      'getAllAccounts',
      'getCircles',
    ]),
    scrollTop: function(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
}
</script>