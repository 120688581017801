<template>
  <div class="container-search" @mouseleave="onMouseleave">
    <div class="wrap">
      <div class="standard" v-show="Boolean(standardSearchDisplay)">
        <div class="searchbox">
          <input type="text" placeholder="つぶやきをさがす" v-model="keywords">
          <button id="searchbutton" type="submit" @click="set()">検索</button>
        </div>
        <button class="advancedbutton" type="submit" @click="()=>{standardSearchDisplay = !standardSearchDisplay}">詳細検索</button>
      </div>
      <div class="advanced" v-show="!Boolean(standardSearchDisplay)">
        <div class="searchbox">
          <input type="text" placeholder="キーワードを入力" v-model="keywords">
        </div>
        <div class="settings">
          <div class="selectwrap">
            <select name="a" v-model="circleId" @focus="toggleFocusCircleSelectFlg" @blur="toggleFocusCircleSelectFlg">
              <option value="" style="display:block;">サークル</option>
              <option v-for="(circle, index) in circles" v-bind:value="circle.id" :key="index">{{circle.circle_name}}</option>
            </select>
          </div>
          <div class="selectwrap">
            <select name="b" v-model="accountId" @focus="toggleAccountSelectFlg" @blur="toggleAccountSelectFlg">
              <option value="" style="display:block;">つぶやいた人</option>
              <option v-for="(account, index) in destinations" v-bind:value="account.id" :key="index">{{account.last_name + account.first_name}}</option>
            </select>
          </div>
          <div class="selectwrap">
            <select name="c" v-model="hashtagId" @focus="toggleHashtagSelectFlg" @blur="toggleHashtagSelectFlg">
              <option value="" style="display:block;">話題</option>
              <option v-for="(hashtag, index) in hashtags" v-bind:value="hashtag.mst_topic_id" :key="index">{{hashtag.mst_topic_name}}</option>
            </select>
          </div>
          <button id="searchbutton" type="submit" @click="set()">検索</button>
        </div>
        <button class="backbutton" type="submit" @click="()=>{standardSearchDisplay = !standardSearchDisplay}">フリーワードに戻る</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import _ from 'lodash'
import Global from '../../js/constant'

export default {
  data () {
    return {
      keywords     : '',
      circleId     : '',
      officialCircle : null,
      accountId    : '',
      hashtagId    : '',
      circles      : null,
      destinations : null,
      allDestinations:null,
      hashtags     : null,
      searchStates : [],
      standardSearchDisplay : true,
      advancedSearchDisplay : false,
      focusCircleSelect: false,
      focusAccountSelect: false,
      focusHashtagSelect: false,
    }
  },
  computed:{
    ...mapState([
      'loginFlg',
      'account',
      'keywordSearchEntries',
      'keywordSearchContainerDisplay'
    ])
  },
  watch : {
    account : function () {
      this.getCircles()
      // this.getDestinations()
      // this.getHashtags()
    },
    circleId : function (newVal) {
      let circle = _.filter(this.circles, x => x.id == newVal)
      this.officialCircle = circle[0].publish
      this.destinations = circle[0].members
      this.getHashtags()
    },
    // keywordSearchContainerDisplay : function (val) {
      // alert(val)
    // }
  },
  mounted : function () {
    this.getCircles()
    // this.getDestinations()
    // this.getHashtags()
  },
  methods:{
    ...mapMutations([
      'setKeywords',
      'setKeywordSearchEntries',
      'setKeywordSearchContainerDisplay',
      'setClientErrors',
    ]),
    set:function(){
      if (!this.keywords) {
        this.$toasted.error('please input keyword')
        return
      }
      this.setKeywords(this.keywords)
      this.setKeywordSearchEntries([])
      this.searchStates = []

      this.$router.push( { path : '/keyword-search'}, () => {} )

      this.setKeywordSearchContainerDisplay(false)
      /*
        キーワードを	
        keyword_search_division_id : {
          entry : 1,
          account_name : 2,
          circle_name : 3,
          hashtag_name : 4
        }
        単位で並行的に検索を実行する
        そのためinitSearchStatesにて
        並行処理でも管理できるようにオブジェクトの配列を生成
      */
      this.initSearchStates()

      for (let idx in this.searchStates) {
        this.get(idx)
      }


      //全てのstatesがcompletedになるまで待つ
      let filteredStates = []
      do {
        filteredStates = _.filter(this.searchStates, (item)=>{
          return _.includes(item.completed, false)
        })

      } while (filteredStates.length > 0)

      // this.setSearchContainerDisplay(!this.searchContainerDisplay)
    },

    get : function(idx) {
      let row = this.searchStates[idx]
      let params = {
        entry_division_id          : row.entry_division_id,
        keyword_search_division_id : row.keyword_search_division_id,
        keyword                    : row.keyword,
        login_account_id           : row.login_account_id,
      }
      if (this.circleId) params['circle_id'] = this.circleId
      if (this.accountId) params['account_id'] = this.accountId
      if (this.hashtagId) params['hashtag_id'] = this.hashtagId 

      this.axios.post(Global.url.api_keyword_search, params)
      .then(res => {
        if (res.data.status == Global.api_status.success) {
          //新規entryのみ絞り込み
          let newEntries = []
          res.data.entries.forEach(entry => {
            let duplicateEntries = _.filter(this.keywordSearchEntries, (item)=>{
              return _.includes(item.id, entry.id)
            })
            if (duplicateEntries.length == 0) {
              newEntries.push(entry)
            }
          });

          this.setKeywordSearchEntries(newEntries)
          return
        }
        this.setClientErrors({func:"keyword-search/search.vue::get", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"keyword-search/search.vue::get", params: params, errorMessage:error})
      })
      .finally(()=>{
        row.completed = true
      })      
    },

    initSearchStates : function () {
      // eslint-disable-next-line
      let _keywords = this.keywords.replace(/　/g," ");
      let keywordArrays = _keywords.split(' ')
      for (let idx in keywordArrays) {
        if (keywordArrays[idx].trim() == '') continue
        for (let key in Global.keyword_search_division_id) {
          this.searchStates.push({
            'entry_division_id'          : Global.entry_division_id.keyword_search,
            'keyword_search_division_id' : Global.keyword_search_division_id[key],
            'keyword'                    : keywordArrays[idx],
            'login_account_id'           : this.account&&this.account.id,
            'completed'                  : false 
          })
        }
      }
    },

    getCircles :function() {
      let params = {}
      if (this.account != null) params['account_id'] = this.account.id

      this.axios.post(this.Global.url.api_all_circles, params).then(res=>{
        if (res.data.status == this.Global.api_status.success) {
          this.circles = res.data.circles
          return
        }
        this.setClientErrors({func:"keyword-search/search.vue::getCircles", params: params, errorMessage:JSON.stringify(res.data)})
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"keyword-search/search.vue::getCircles", params: params, errorMessage:error})
      })
    },

    // getDestinations : function () {
    //   let params = {}
    //   if (this.account) params['account_id'] = this.account.id
    //   this.axios.post(this.Global.url.api_accounts_all, params).then(res=>{
    //     if (res.data.status == this.Global.api_status.success) {
    //       this.destinations = res.data.accounts
    //       this.allDestinations = this.destinations
    //       return
    //     }
    //     this.$toasted.error("error")
    //     console.dir(res.data.errors)
    //   })
    //   .catch(error=>{
    //     this.$toasted.error('client error')
    //     this.setClientErrors({func:"keyword-search/search.vue::getDestinations", params: params, errorMessage:error})
    //   })
    // },
    getHashtags : function () {
      if (this.circleId == null || this.officialCircle ==null) return
      let params = {
        circle_id : this.circleId,
        official_circle : this.officialCircle
      }
      this.axios.post(this.Global.url.api_circle_topics_search, params).then(res=>{
        if (res.data.status == this.Global.api_status.success) {
          this.hashtags = res.data.hashtags
          return
        }
        // this.$toasted.error("error")
        this.setClientErrors({func:"keyword-search/search.vue::getHashtags", params: params, errorMessage:JSON.stringify(res.data)})
        // console.dir(res.data.errors)
      })
      .catch(error=>{
        this.$toasted.error('client error')
        this.setClientErrors({func:"keyword-search/search.vue::getHashtags", params: params, errorMessage:error})
      })
    },
    toggleFocusCircleSelectFlg:function() {
      // alert('focus')
      this.focusCircleSelect = !this.focusCircleSelect
    },
    toggleAccountSelectFlg: function() {
      this.focusAccountSelect = !this.focusAccountSelect
    },
    toggleHashtagSelectFlg: function() {
      this.focusHashtagSelect = !this.focusHashtagSelect
    },
    onMouseleave:function () {
      if (this.focusCircleSelect || this.focusAccountSelect || this.focusHashtagSelect) return
      
      this.setKeywordSearchContainerDisplay(false)
    }
  }
}
</script>