<template>
  <nav>
    <ul>
      <!-- <li><router-link to ="/introduction">はじめに</router-link></li> -->
      <!-- <li><router-link to ="/question">よくある質問</router-link></li> -->
      <li :class="[ {current : divisionId == privacyPolicy } ]"><router-link to ="privacy-policy">プライバシーポリシー</router-link></li>
      <li :class="[ {current : divisionId == termsService  } ]"><router-link to ="/terms-service">利用規約</router-link></li>
      <!-- <li><router-link to ="/case-study">活用事例</router-link></li> -->
      <!-- <li><router-link to="/contact-us">お問い合わせ</router-link></li> -->
    </ul>
    <div class="back"><router-link to ="/">ともつくネットへ戻る</router-link></div>
  </nav>
</template>
<script>
export default {
  props : {
    // screenIndex : 
    divisionId : {
      type:Number,
      required:true,
    }
  },
  data () {
    return {
      entryId : null,
      entries : null,

    }
  },
  computed : {
    // ...mapState([
    //   'loginFlg',
    //   'account'
    //   ])

    privacyPolicy : function () {
      return 1
    },
    termsService : function () {
      return 2
    }
  },

  methods:{
  }
}

</script>